import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout pageTitle="404 NOT FOUND">
    <p className="notFound__description">It is hard to understand..<br />How did you came here??</p>
    <Link className="notFound__button" to="/"><span role="img" aria-label="Home">🏠</span> Go back home</Link>
  </Layout >
)

export default NotFoundPage
